import { useCallback, useContext, useMemo } from "react";
import { DriversWithSubDrivers, SubDriver } from "@/services/driverService";
import { DriversContext } from "@/contexts/DriversContext";
import { flatten } from "lodash";

export type FindDriverOrSubdriverByCode = (
  code: string
) => { driver: DriversWithSubDrivers; subDriver?: SubDriver } | undefined;

/**
 * Exposes drivers with subdrivers global context
 * @returns Drivers with subdrivers global context
 */
export function useDriverContext(): {
  drivers: DriversWithSubDrivers[];
  loading: boolean;
  error: undefined | unknown;
  findDriverOrSubdriverByCode: FindDriverOrSubdriverByCode;
} {
  const { drivers, loading, error } = useContext(DriversContext);

  const driversByCode: Map<string, DriversWithSubDrivers> = useMemo(() => {
    return new Map(drivers.map((driver) => [driver.driverCode, driver]));
  }, [drivers]);

  const subDriversByCode: Map<string, SubDriver> = useMemo(() => {
    return new Map(
      flatten(
        drivers.map((driver) => {
          return driver.subDrivers.map((subDriver) => [subDriver.subDriverCode, subDriver]);
        })
      )
    );
  }, [drivers]);

  const findDriverOrSubdriverByCode = useCallback(
    (code: string): { driver: DriversWithSubDrivers; subDriver?: SubDriver } | undefined => {
      if (loading) return undefined;

      const driver = driversByCode.get(code);
      if (driver) {
        return { driver };
      }
      // Driver code not found, try to find subdriver
      const subDriver = subDriversByCode.get(code);
      if (subDriver) {
        const driver = driversByCode.get(subDriver.driverCode);
        if (driver) {
          return { driver, subDriver };
        }
      }
      return undefined;
    },
    [loading, driversByCode, subDriversByCode]
  );

  return {
    drivers,
    loading,
    error,
    findDriverOrSubdriverByCode,
  };
}
