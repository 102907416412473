import { Command } from "cmdk";
import { Box, Typography } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { useTranslation } from "react-i18next";
import { usePermission } from "@/hooks/usePermissions";
import { PermissionCode } from "@/types/PermissionCode";

type Props = {
  onSelectPage: (page: string) => void;
};

const CommandMenuActions = ({ onSelectPage }: Props) => {
  const { t } = useTranslation();
  const { granted: hasImpersonatePermission } = usePermission(PermissionCode.USER_IMPERSONATE);

  return (
    <Command.Group
      heading={
        <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
          <Typography variant="overline" color="text.secondary">
            {t("common.labels.actions")}
          </Typography>
          <BoltIcon sx={{ color: "text.secondary" }} />
        </Box>
      }
      className="cmdk-group"
    >
      <Command.Item
        keywords={["change", "organization", t("common.labels.actions")]}
        key="change-organization"
        onSelect={() => onSelectPage("change-organization")}
        className="cmdk-item"
      >
        {t("common.labels.changeOrganization")}
      </Command.Item>

      {hasImpersonatePermission ? (
        <Command.Item
          key="impersonate"
          keywords={["impersonate", t("common.labels.actions")]}
          onSelect={() => onSelectPage("impersonate")}
          className="cmdk-item"
        >
          {t("common.labels.impersonate")}
        </Command.Item>
      ) : null}

      <Command.Item
        key="change-language"
        keywords={["change", "language", t("common.labels.actions")]}
        onSelect={() => onSelectPage("change-language")}
        className="cmdk-item"
      >
        {t("common.labels.changeLanguage")}
      </Command.Item>

      <Command.Item
        key="search-employees"
        keywords={["search", "users", t("common.labels.actions")]}
        onSelect={() => onSelectPage("search-employees")}
        className="cmdk-item"
      >
        {t("common.labels.searchEmployees")}
      </Command.Item>
    </Command.Group>
  );
};

export default CommandMenuActions;
