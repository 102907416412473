import useAuth from "@/hooks/useAuth";
import { FC } from "react";

export const useIsLaraUser = (): boolean => {
  const { user } = useAuth();

  return !!user && user.organizationReference === "lara";
};

export const OnlyLaraUserWrapper: FC = ({ children }) => {
  const isLaraUser = useIsLaraUser();

  if (!isLaraUser) {
    return null;
  }

  return <>{children}</>;
};
