import { FC, useEffect, useRef } from "react";

interface IntersectionObserverElementProps {
  onScrollIntersect: () => void;
  threshold?: number;
}

export const IntersectionObserverElement: FC<IntersectionObserverElementProps> = ({
  onScrollIntersect,
  threshold = 1,
}) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onScrollIntersect();
        }
      },
      { threshold }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget.current, onScrollIntersect]);

  return <div ref={observerTarget}></div>;
};
