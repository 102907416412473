import { Typography } from "@mui/material";

import { Command } from "cmdk";
import { useTranslation } from "react-i18next";

const CommandMenuNoResultsFound = () => {
  const { t } = useTranslation();
  return (
    <Command.Empty>
      <Typography variant="body1" textAlign="center">
        {t("common.labels.noResultsFound")}
      </Typography>
    </Command.Empty>
  );
};

export { CommandMenuNoResultsFound };
