import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
  Chip,
  Typography,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { valueAsArray } from "@/utils/valueAsArray";
import { SxProps } from "@mui/system";
import { FC } from "react";
import { useDeepCompareEffect } from "use-deep-compare";

export type SelectOption = {
  label: string | React.ReactElement;
  value: string;
  icon?: React.ReactElement;
  disabled?: boolean;
};
export type SelectOptions = SelectOption[];

export type SelectInputProps = Omit<SelectProps, "placeholder"> & {
  name: string;
  options: SelectOptions;
  defaultOption?: SelectOption;
  variant?: "outlined" | "standard" | "filled";
  onChange: TextFieldProps["onChange"];
  helperText?: string | any;
};

function getMenuOptionStyle(current: string, selected: string[]): SxProps {
  return selected.includes(current) ? { background: "#f6f3e8 !important" } : {};
}

export const SelectInput: FC<SelectInputProps> = ({
  name,
  options,
  defaultOption,
  onChange,
  value,
  variant,
  size = "medium",
  label,
  multiple,
  error,
  required,
  fullWidth,
  helperText,
  ...props
}) => {
  useDeepCompareEffect(() => {
    if (!multiple && value !== defaultOption?.value && !options.find((o) => o.value === value)) {
      const nextValue = defaultOption?.value || null;
      onChange &&
        onChange({
          target: {
            name,
            ...(nextValue !== null ? { value: nextValue } : undefined),
          },
        } as any);
    }
  }, [options, value]);

  const onChangeHandler = ({ target }) => {
    let selectedValue = target.value;

    if (multiple) {
      let vArray = valueAsArray(value);

      if (vArray.includes(selectedValue)) {
        vArray = vArray.filter((v) => v !== selectedValue);
      } else {
        vArray.push(selectedValue);
      }

      selectedValue = vArray;
    }

    onChange &&
      onChange({
        target: {
          name,
          ...(selectedValue !== null ? { value: selectedValue } : undefined),
        },
      } as any);
  };

  const itemValues: string[] = valueAsArray(value);

  return (
    <FormControl sx={{ display: "flex", width: fullWidth ? "100%" : "auto" }} size={size}>
      <InputLabel id={`select-input-label-${name}`} error={error} required={required}>
        {label}
      </InputLabel>
      <Select
        size={size}
        labelId={`select-input-label-${name}`}
        id={`select-input-${name}`}
        value={itemValues}
        name={name}
        error={error}
        onChange={onChangeHandler}
        input={<OutlinedInput label={label} />}
        fullWidth={fullWidth}
        renderValue={() => (
          <>
            {itemValues.map((value, i) => {
              const label = options.find((o) => o.value === value)?.label || defaultOption?.label;
              const icon = options.find((o) => o.value === value)?.icon || defaultOption?.icon;
              return (
                <React.Fragment key={`select-input-${label}-${i}`}>
                  {multiple ? (
                    <Chip label={label} sx={{ mr: 0.5, fontSize: 11, height: 20 }} />
                  ) : (
                    <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {icon}
                      {label}
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </>
        )}
        {...props}
      >
        {defaultOption && (
          <MenuItem
            sx={{ ...getMenuOptionStyle(defaultOption.value, itemValues), gap: 1 }}
            value={defaultOption.value}
            key={`select-${defaultOption.value}`}
          >
            {defaultOption.icon}
            {defaultOption.label}
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem
            sx={{ ...getMenuOptionStyle(option.value, itemValues), gap: 1 }}
            key={`select-${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
