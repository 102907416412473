import { createTargetEvent } from "@/utils/createTargetEvent";
import { Checkbox, FormControlLabel, FormControlLabelProps, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { ComponentProps, FC } from "react";

export type CheckboxInputProps = Omit<FormControlLabelProps, "onChange" | "control"> & {
  onChange: (event: { target: { name: string; value: boolean } }, value: boolean) => void;
  value?: boolean;
  name: string;
  showAsSwitch?: boolean;
  inputProps?: ComponentProps<typeof Checkbox | typeof Switch>;
};

export const CheckboxInput: FC<CheckboxInputProps> = ({
  onChange,
  name,
  value,
  label,
  showAsSwitch,
  inputProps,
  ...props
}) => {
  function handleChangeCheckbox() {
    onChange(createTargetEvent(name, !value), !value);
  }

  return (
    <Box
      sx={{
        justifyContent: "flex-start",
        height: "56px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        pl: 1.5,
        ...(props.sx || {}),
      }}
    >
      <FormControlLabel
        {...props}
        name={name}
        label={label}
        onChange={handleChangeCheckbox}
        value={Boolean(value)}
        checked={Boolean(value)}
        control={!showAsSwitch ? <Checkbox {...inputProps} /> : <Switch {...inputProps} />}
      />
    </Box>
  );
};
