import { searchBasicUsers } from "@/services/userService";
import { Typography } from "@mui/material";

import { LinearProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Command } from "cmdk";
import { uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import { CommandMenuNoResultsFound } from "./CommandMenuNoResultsFound";
import useAuth from "@/hooks/useAuth";

type Props = {
  search: string;

  onImpersonate: () => void;
};

const CommandMenuImpersonatePage = ({ search, onImpersonate }: Props) => {
  const { t } = useTranslation();
  const { impersonate, user: currentUser } = useAuth();

  const usersQuery = useQuery({
    queryKey: ["user.search", search],
    queryFn: async () => {
      const users = await searchBasicUsers({ s: search });
      const uniqUsers = uniqBy(users, "id");
      return uniqUsers;
    },
    enabled: true,
  });

  const onSelectUser = (userId: string) => {
    impersonate(userId);
    onImpersonate();
  };

  return (
    <>
      <Command.Group heading={t("common.labels.impersonate")} className="cmdk-group">
        {usersQuery.isLoading ? (
          <LinearProgress />
        ) : (
          usersQuery?.data?.map((user) => (
            <Command.Item key={user.id} onSelect={() => onSelectUser(user.id)} className="cmdk-item">
              <Typography variant="body1">
                {user.firstName} {user.lastName}{" "}
                <Typography variant="caption" color="text.secondary">
                  ({user.email})
                </Typography>
              </Typography>
              {user.email === currentUser?.email ? (
                <Typography variant="caption" color="text.secondary">
                  {t("common.labels.current")}
                </Typography>
              ) : null}
            </Command.Item>
          ))
        )}
      </Command.Group>
      {!usersQuery.isLoading && <CommandMenuNoResultsFound />}
    </>
  );
};

export { CommandMenuImpersonatePage };
