import { Modal, Paper, styled } from "@mui/material";
import { Command } from "cmdk";
import { useState } from "react";
import CommandMenuToolbar from "./CommandMenuToolbar";
import CommandMenuActions from "./CommandMenuActions";
import { CommandMenuNavigation } from "./CommandMenuNavigation";
import { CommandMenuSearchEmployeesPage } from "./CommandMenuSearchEmployeesPage";
import { CommandMenuImpersonatePage } from "./CommandMenuImpersonatePage";
import { CommandMenuChangeLanguagePage } from "./CommandMenuChangeLanguagePage";
import { CommandMenuChangeOrganizationPage } from "./CommandMenuChangeOrganizationPage";
import { Shortcut } from "@/types/shortcuts";
import { useHotkeys } from "react-hotkeys-hook";
import { CommandMenuNoResultsFound } from "./CommandMenuNoResultsFound";

const ModalContent = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 50dvh;
  box-shadow: ${({ theme }) => theme.shadows[8]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;
`;

const StyledCommand = styled(Command)`
  overflow: scroll;
  position: relative;
  .cmdk-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    position: sticky;
    top: 0;
  }

  .cmdk-input {
    width: 100%;
    font-size: 20px;
    border: none;
    background-color: ${({ theme }) => theme.palette.background.paper};
    outline: none;
    transition: border-color 0.2s;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    padding: 24px 16px;
  }

  .cmdk-item {
    padding: ${({ theme }) => theme.spacing(1, 2)};
    margin: ${({ theme }) => theme.spacing(0.5, 0)};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    cursor: pointer;
    transition: background-color 0.2s;

    &[data-selected="true"] {
      background-color: ${({ theme }) => theme.palette.action.selected};
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
  }

  .cmdk-group-heading {
    padding: ${({ theme }) => theme.spacing(1, 2)};
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
  }

  .cmdk-empty {
    padding: ${({ theme }) => theme.spacing(2)};
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: center;
  }

  .cmdk-list {
    overflow: scroll;
    padding: 16px;
    max-height: calc(100% - 72px);
    overflow: scroll;
  }
`;

const CommandMenu = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState<string[]>([]);
  const page = pages[pages.length - 1];

  useHotkeys(
    [Shortcut.COMMAND_MENU_WINDOWS, Shortcut.COMMAND_MENU_WINDOWS_ALTERNATIVE, Shortcut.COMMAND_MENU_MAC],
    () => {
      setOpen((open) => !open);
      setPages([]);
      setSearch("");
    },
    { enableOnContentEditable: true, enableOnFormTags: true }
  );

  const close = () => {
    setOpen(false);
    setPages([]);
    setSearch("");
  };

  const onSelectPage = (page: string) => {
    setPages((pages) => [...pages, page]);
    setSearch("");
  };

  const onBack = () => {
    setPages((pages) => pages.slice(0, -1));
    setSearch("");
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} disableAutoFocus>
      <ModalContent id="command-menu-modal-content">
        <StyledCommand
          label="Command Menu"
          onKeyDown={(e) => {
            // Escape goes to previous page
            // Backspace goes to previous page when search is empty
            if (e.key === "Escape" || (e.key === "Backspace" && !search)) {
              e.preventDefault();
              onBack();
            }
          }}
        >
          <CommandMenuToolbar showBackButton={pages.length > 0} search={search} setSearch={setSearch} onBack={onBack} />

          <Command.List className="cmdk-list">
            {!page && (
              <>
                <CommandMenuNavigation onNavigate={close} />
                <CommandMenuActions onSelectPage={onSelectPage} />
                <CommandMenuNoResultsFound />
              </>
            )}

            {page === "change-language" && <CommandMenuChangeLanguagePage />}
            {page === "change-organization" && <CommandMenuChangeOrganizationPage />}
            {page === "impersonate" && <CommandMenuImpersonatePage search={search} onImpersonate={close} />}
            {page === "search-employees" && <CommandMenuSearchEmployeesPage search={search} onSelect={close} />}
          </Command.List>
        </StyledCommand>
      </ModalContent>
    </Modal>
  );
};

export { CommandMenu };
