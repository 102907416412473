import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { Command } from "cmdk";
import { useTranslation } from "react-i18next";

type Props = {
  search: string;
  showBackButton: boolean;
  setSearch: (search: string) => void;
  onBack: () => void;
};

const CommandMenuToolbar = ({ search, showBackButton, setSearch, onBack }: Props) => {
  const { t } = useTranslation();
  return (
    <Box className="cmdk-toolbar">
      {showBackButton ? (
        <IconButton
          color="primary"
          size="large"
          onClick={onBack}
          sx={{
            padding: "8px",
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      ) : null}
      <Command.Input
        value={search}
        onValueChange={setSearch}
        className="cmdk-input"
        placeholder={t("common.labels.search")}
        autoFocus
      />
    </Box>
  );
};

export default CommandMenuToolbar;
