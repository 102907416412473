import { baseUrl, env } from "@/config";
import axios, { AxiosError } from "axios";
import AxiosMockAdapter from "axios-mock-adapter";
import { find } from "lodash";

const UNAUTHORIZED_STATUS_CODE = 401;

const INVALID_TOKEN_ERRORS = [
  "Token invalid for methods JWT",
  "Token not set for methods JWT",
  "Token invalid for methods API_KEY",
  "Token not set for methods API_KEY",
];

export const isAxiosError = axios.isAxiosError;

export const isNotFoundAxiosError = (error: AxiosError): boolean => {
  return error.response?.status === 404;
};
export const isConflictAxiosError = (error: AxiosError, errorCode?: string): boolean => {
  if (errorCode) {
    return (
      error.response?.status === 409 && (error.response?.data as undefined | Record<string, any>)?.code === errorCode
    );
  }
  return error.response?.status === 409;
};

export function isModelValidationError(error: AxiosError, forProperty?: string): boolean {
  return (
    (error.response?.data as undefined | Record<string, string>)?.code === "model_validation_error" &&
    (forProperty === undefined ||
      (error.response?.data as undefined | Record<string, any>)?.context?.errors?.some(
        (e: { property: string }) => e.property === forProperty
      ))
  );
}
/**
 *
 * @param error
 * @param property
 * @returns the specific errors of the property
 */
export const getModelValidationErrorsForProperty = (error: AxiosError, property: string): string[] | undefined => {
  if (!isModelValidationError(error, property)) return;
  const errors = find(
    (error.response?.data as undefined | Record<string, any>)?.context?.errors,
    (e: { property: string }) => e.property === property
  );
  return errors.errors;
};

export function isEntityAlreadyExistsError(error: unknown): error is AxiosError<{
  code: "bad_request";
  message: "Entity already exists";
  context: {};
  origin: string;
}> {
  if (!isAxiosError(error)) return false;
  return (error.response?.data as Record<string, unknown>)?.message === "Entity already exists";
}

axios.defaults.withCredentials = true;

export const axiosWithoutOrg = axios.create({
  baseURL: baseUrl.dashboardApi,
  withCredentials: true,
});

export const dashboardAxios = axios.create({
  baseURL: baseUrl.dashboardApi,
  withCredentials: true,
});

export const originalOrgDashboardAxios = axios.create({
  baseURL: baseUrl.dashboardApi,
  withCredentials: true,
});

// axiosWithoutOrg is used for login calls, so it almost never needs to be intercepted
// by the unauthorized interceptor.
[dashboardAxios, originalOrgDashboardAxios].forEach((axiosInstance) => {
  axiosInstance.interceptors.response.use(undefined, function (error: AxiosError) {
    if (
      error.response?.status === UNAUTHORIZED_STATUS_CODE &&
      (error.response?.data as undefined | Record<string, any>)?.context?.requiredChecks?.some((rc) =>
        INVALID_TOKEN_ERRORS.includes(rc)
      )
    ) {
      document.location = `/authentication/login?reason=expired${
        document.location.pathname !== "/" ? `&redirect=${document.location.pathname}` : ""
      }`;
    }

    return Promise.reject(error);
  });
});

let mock: AxiosMockAdapter;

if (env.addMockRequests) {
  [originalOrgDashboardAxios, dashboardAxios].forEach((a) => {
    mock = new AxiosMockAdapter(a, { delayResponse: 0 });
    mock.onAny(/^(?!\/api).*/).passThrough();
  });

  //  require("../__mocks__");
}

export { mock };
