import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AvatarSize, EmployeeSummary } from "@/types/common";
import { Box, Skeleton } from "@mui/material";
import { FC, useEffect, MutableRefObject, useState } from "react";
import UserAvatar from "../UserAvatar";
import isFormerEmployee from "@/utils/isFormerEmployee";
import { StyledPopperCard } from "../StyledPopperCard";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import { getEmployeeSummary } from "@/services/employeeService";
import { ProfileItemInfo } from "./ProfileItemInfo";
import { usePromise } from "@/hooks/usePromise";
import { useEmployeeFields } from "@/hooks/useEmployeeFields";

const SKIP_FIELD_CODE_IN_PROFILE_SUMMARY_ITEMS = [
  "jobTitle", // Skip job title because it is already displayed in the employee card
];

interface ProfileSummaryProps {
  anchorEl: MutableRefObject<HTMLElement | undefined> | null;
  size?: AvatarSize;
  employeeReference: string;
  mr?: number;
}

const cacheByEmployeeReference: Record<string, EmployeeSummary> = {};

export const ProfileSummaryLayout: FC<{
  anchorEl: MutableRefObject<HTMLElement | undefined>;
  open: boolean;
}> = ({ children, anchorEl, open }) => {
  return (
    <StyledPopperCard open={open} anchorEl={anchorEl.current} placement="top-start">
      <Card sx={{ pb: 0, minWidth: 180 }}>{children}</Card>
    </StyledPopperCard>
  );
};
export const ProfileSummary: FC<ProfileSummaryProps> = ({ employeeReference, size = AvatarSize.NORMAL, anchorEl }) => {
  const [open, setOpen] = useState(false);
  const { fields, findEmployeeField } = useEmployeeFields();

  const fontSize = size === AvatarSize.SMALL ? 10 : 12;

  const fetchEmployee = usePromise(async () => {
    if (anchorEl && anchorEl?.current && open && employeeReference) {
      if (cacheByEmployeeReference[employeeReference]) {
        return cacheByEmployeeReference[employeeReference];
      }
      const employee = await getEmployeeSummary(employeeReference);
      cacheByEmployeeReference[employeeReference] = employee;

      return employee;
    }
  }, [employeeReference, open]);

  useEffect(() => {
    if (anchorEl && anchorEl.current) {
      anchorEl.current.addEventListener("mouseenter", () => {
        setOpen(true);
      });
      anchorEl.current.addEventListener("mouseleave", () => {
        setOpen(false);
      });

      return () => {
        anchorEl?.current?.removeEventListener("mouseenter", () => {});
        anchorEl?.current?.removeEventListener("mouseleave", () => {});
      };
    }
  }, [anchorEl, anchorEl?.current]);

  if (!anchorEl || !anchorEl?.current) {
    return null;
  }

  const employeeMetadataFields = fields.filter((f) => f.options.visibility.showInEmployeeSummary);

  return fetchEmployee.render(
    () => (
      <ProfileSummaryLayout anchorEl={anchorEl} open={open}>
        <CardHeader
          avatar={<UserAvatar skeleton size={size} name={""} />}
          title={<Skeleton width={100} height={10} variant="rectangular" sx={{ borderRadius: 1, mb: 1 }} />}
          subheader={<Skeleton width={120} height={8} variant="rectangular" sx={{ borderRadius: 1 }} />}
        />
        <CardContent sx={{ py: 0, paddingBottom: `8px!important` }}>
          <Box style={{ marginBottom: 8 }}>
            <Skeleton width={"100%"} />
            <Skeleton width={"100%"} />
          </Box>
          <Skeleton width={"100%"} />
        </CardContent>
      </ProfileSummaryLayout>
    ),
    () => null,
    (employeeData: EmployeeSummary) => {
      const formerEmployee = isFormerEmployee(employeeData.leavingDate);
      return (
        <ProfileSummaryLayout anchorEl={anchorEl} open={open}>
          <CardHeader
            avatar={
              <UserAvatar
                src={employeeData.avatar ?? undefined}
                name={`${employeeData.firstName} ${employeeData.lastName}`}
                size={size}
                leavingDate={employeeData.leavingDate ?? undefined}
              />
            }
            title={
              <Typography fontWeight={"bold"} fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
                {`${employeeData.firstName} ${employeeData.lastName}`}
              </Typography>
            }
            subheader={
              findEmployeeField("jobTitle")?.options.visibility.showInEmployeeSummary ? (
                <Typography fontSize={fontSize} color={formerEmployee ? "textSecondary" : undefined}>
                  {employeeData.jobTitle}
                </Typography>
              ) : null
            }
          />
          <CardContent sx={{ py: 0, paddingBottom: `8px!important` }}>
            {employeeMetadataFields.map((field) =>
              employeeData[field.fieldCode] && !SKIP_FIELD_CODE_IN_PROFILE_SUMMARY_ITEMS.includes(field.fieldCode) ? (
                <ProfileItemInfo
                  key={field.fieldId}
                  value={employeeData[field.fieldCode]}
                  translation={field.translation}
                  fontSize={fontSize}
                  Icon={LabelImportantIcon}
                />
              ) : null
            )}
          </CardContent>
        </ProfileSummaryLayout>
      );
    }
  );
};
