export enum Shortcut {
  /** Shortcut for focusing the organization switcher
   * Mac     :: ⌘  + ⌥ + o
   * Windows :: ctrl + alt + o
   */
  SWITCH_ORGANIZATION = "mod+alt+o",
  /** Shortcut for open the command menu
   * Mac     :: ⌘ + k
   * Windows :: ctrl + k
   */
  COMMAND_MENU_MAC = "meta+k",
  COMMAND_MENU_WINDOWS = "mod+/",
  COMMAND_MENU_WINDOWS_ALTERNATIVE = "ctrl+alt+k",
}
