import { useQuery } from "@tanstack/react-query";
import { listOrganizations } from "@/services/userService";
import { Typography } from "@mui/material";
import { Command } from "cmdk";
import { useTranslation } from "react-i18next";
import useAuth from "@/hooks/useAuth";
import { CommandMenuNoResultsFound } from "./CommandMenuNoResultsFound";

const CommandMenuChangeOrganizationPage = () => {
  const { t } = useTranslation();
  const { changeOrg, organizationReference } = useAuth();

  const organizationsQuery = useQuery({
    queryKey: ["search.organizations"],
    queryFn: () => listOrganizations(),
  });

  const onChangeOrganization = (organization: string) => {
    changeOrg(organization);
  };

  const organizations = organizationsQuery?.data?.map((o) => ({ label: o.name, value: o.code }));

  return (
    <>
      <Command.Group heading={t("common.labels.changeOrganization")} className="cmdk-group">
        {organizations?.map((organization) => (
          <Command.Item
            key={organization.value}
            onSelect={() => onChangeOrganization(organization.value)}
            className="cmdk-item"
          >
            <Typography variant="body1">{organization.label}</Typography>
            {organization.value === organizationReference ? (
              <Typography variant="caption" color="text.secondary">
                {t("common.labels.current")}
              </Typography>
            ) : null}
          </Command.Item>
        ))}
      </Command.Group>
      {!organizationsQuery.isFetching && <CommandMenuNoResultsFound />}
    </>
  );
};

export { CommandMenuChangeOrganizationPage };
