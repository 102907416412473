import useAuth from "@/hooks/useAuth";
import LoadingScreen from "@/modules/skeleton/components/LoadingScreen";
import { EmployeeField, getEmployeeFieldsDefinition } from "@/services/employeeService";
import { FC, createContext, useEffect, useState } from "react";

interface EmployeeFieldsContextValue {
  fields: EmployeeField[];
  loading: boolean;
  error: unknown | null;
}

export const EmployeeFieldsContext = createContext<EmployeeFieldsContextValue>({
  fields: [],
  loading: true,
  error: null,
});

export const EmployeeFieldsProvider: FC = ({ children }) => {
  const auth = useAuth();
  const [fields, setFields] = useState<EmployeeField[]>([]);
  const [awaitingFetchFields, setAwaitingFetchFields] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown | null>();

  useEffect(() => {
    if (awaitingFetchFields) return;

    setLoading(true);
    setError(null);

    if (!auth.isAuthenticated || !auth.organizationReference) {
      setFields([]);
      return;
    }

    const load = async () => {
      try {
        setAwaitingFetchFields(true);

        if (!auth.organizationReference) {
          throw new Error("organizationReference is not defined");
        }

        const fieldsDefinitionResponse = await getEmployeeFieldsDefinition(auth.organizationReference);

        setError(null);
        setFields(fieldsDefinitionResponse);
      } catch (err: unknown) {
        console.error("[EMPLOYEE FIELDS] Error loading fields", err);
        setFields([]);
        setError(err);
      } finally {
        setAwaitingFetchFields(false);
        setLoading(false);
      }
    };

    load();
  }, [auth.organizationReference]);

  return (
    <EmployeeFieldsContext.Provider value={{ fields, loading, error }}>
      {auth.isAuthenticated && loading ? <LoadingScreen /> : children}
    </EmployeeFieldsContext.Provider>
  );
};
