import {
  IntegrationSyncResponse,
  S3FileSource,
  SftpFileSource,
  SharepointFileSource,
  SharepointFolderSource,
} from "@/services/integrationService";
import { OldRoleCode, User } from "@/types/user";

export interface EmployeeIntegrationCommunication {
  reference: string;
  channel: IntegrationCommunicationType;
  default: boolean;
  operative: boolean;
  statusMessage?: string;
}

export interface EmployeeBasics {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  avatar?: string;
  hrmsReference: string;
  hrbpName?: string;
  jobTitle?: string;
  department?: string;
  subDepartment?: string;
  location?: string;
  division?: string;
  principalManagerId?: string;
  principalManagerName?: string;
  principalHrbpName?: string;
  country?: string;
  city?: string;
  startedWorkingDate: string;
  engagementScore?: number;
  leavingDate?: string;
  integrationCommunication: EmployeeIntegrationCommunication[];
  faqsEnabled: boolean;
}

export interface EmployeeSummary {
  id: string;
  organizationReference: string;
  uuid: string;
  firstName: string | null;
  lastName: string | null;
  startedWorkingDate: string;
  email: string | null;
  avatar: string | null;
  contactEnabled: boolean;
  faqsEnabled: boolean;
  cohortReference: string | null;
  locale: string | null;
  hrbpId: string | null;
  hrbpIds: string[];
  principalManagerId: string | null;
  hrmsReference: string | null;
  nickname: string | null;
  country: string | null;
  city: string | null;
  location: string | null;
  jobTitle: string | null;
  division: string | null;
  department: string | null;
  subDepartment: string | null;
  team: string | null;
  teamNames: string[];
  gender: string | null;
  workModality: string | null;
  phone: string | null;
  employeeGrade: string | null;
  previousWorkExperienceMonths: number | null;
  performanceScore: string | null;
  jobTitleEffectiveAt: string | null;
  birthdate: string | null;
  leavingDate: string | null;
  leavingReasonType: string | null;
  leavingReasonDetail: string | null;
  isActive: boolean;
  integrations: {
    priorities: {
      communications: string[];
    };
    communications: EmployeeIntegrationCommunication[];
  };
  customizations: Record<string, string>;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  manager: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    nickname: string;
  };
  hrbps: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    nickname: string;
  }[];
  custom1: string | null;
  custom2: string | null;
  custom3: string | null;
  custom4: string | null;
  custom5: string | null;
  custom6: string | null;
  custom7: string | null;
  custom8: string | null;
  custom9: string | null;
  custom10: string | null;
  custom11: string | null;
  custom12: string | null;
  custom13: string | null;
  custom14: string | null;
  custom15: string | null;
  custom16: string | null;
  custom17: string | null;
  custom18: string | null;
  custom19: string | null;
  custom20: string | null;
}

export interface EmployeeBasicsWithMetadata extends EmployeeBasics {
  lastMood?: Mood;
  lastRiskCaseDate?: Date;
  lastRiskCaseStatus?: RiskCaseStatus;
}

export interface EmployeeBasicsWithLastInteraction extends EmployeeBasics {
  employeeEngagementScore?: number;
  lastChatDate?: Date;
  lastChatFinishedDate?: Date;
  lastChatDateEngagementScore?: number;
  onBoardingEngagementScore?: number;
  lastChatId?: string;
  lastChatStatus?: ChatStatus;
  lastAnsweredChatId?: string;
  lastAnsweredChatFinishedDate?: Date;
  lastAnsweredChatDate?: Date;
}

export interface Manager {
  type: string;
  principal: boolean;
  employee: EmployeeBasicsWithMetadata;
}

export interface DriversEngagementValue {
  totalResponses: number;
  engagementScore: number;
}

export type DriversEngagement = {
  [driver: string]: DriversEngagementValue;
};

export interface Employee extends EmployeeBasics {
  managers: Manager[];
  directReports: EmployeeBasicsWithMetadata[];
  averageMood?: Mood;
  hrbps: EmployeeBasics[];
  birthdate?: Date;
  performanceScore?: number;
  phone?: string;
  division: string;
  jobTitleEffectiveAt: Date;
  riskCases: RiskCaseBasics[];
  chats: ChatBasics[];
  drivers: WithParticipation<DriversEngagement>;
}

export interface RiskCaseBasics extends Omit<EmployeeBasics, "id" | "name" | "email" | "avatar" | "leavingDate"> {
  id: string;
  employeeId?: string;
  employeeName: string;
  employeeEmail: string;
  employeeAvatar: string;
  employeeLeavingDate?: string | undefined;
  driversAffected?: DriverAndSubDriver[];
  mood: Mood;
  status: RiskCaseStatus;
  severity?: Severity;
  assignedToReference?: string;
  assignedToId?: string;
  assignedToName?: string;
  assignedToAvatar?: string;
  closedReason: string | null;
  closedDescription: string | null;
  wasUsefull: boolean | null;
  wasUsefullDescription: string | null;
  detectedAt: Date;
  openedAt?: Date;
  closedAt?: Date;
  updatedAt: Date;
  resolutionTime?: number;
  detectedBy: User;
}

export interface LeaderMetric {
  id: string;
  firstName: string;
  lastName: string;
  department: string;
  avatar: string;
  jobTitle: string;
  countDirectReports: number;
  countIndirectReports: number;
  isLineManager: boolean;
  moodResponses: number;
  averageMood: number;
  quantity1: number;
  quantity2: number;
  quantity3: number;
  quantity4: number;
  quantity5: number;
  detectedCases: number;
  oldestDetectedCase: string;
  inProgressCases: number;
  oldestInProgressCase: string;
  totalCasesCurrentQuarter: number;
  totalCasesLastQuarter: number;
  engagementScore?: number;
}

export interface TicketListingView {
  id: string;
  typeId: string;
  typeCode: string;
  sequenceNumber: number;
  employeeReference: string;
  status: string;
  statusCode: string;
  generatedType: string;
  generatedReference: string;
  body: string;
  assignedTo: string;
  metadata: string;
  closedReason: string;
  createdBy: string;
  updatedBy: string;
  doingBy: string;
  closedBy: string;
  createdAt: string;
  updatedAt: string;
  doingAt: string;
  closedAt: string;
  [employeeField: string]: string | number;
}

export enum TicketStatus {
  TO_DO = "to_do",
  DOING = "doing",
  CLOSED = "closed",
}

export type TicketMetadata = Record<string, unknown>;

export interface TicketView {
  id: string;
  organizationReference: string;
  employeeReference: string | null;
  generatedType: string | null;
  generatedReference: string | null;

  status: TicketStatus;
  body: string | null;
  assignedTo: string | null;
  metadata: TicketMetadata;
  closedReason: string | null;

  createdBy: string;
  updatedBy: string;
  doingBy: string | null;
  closedBy: string | null;

  createdAt: string;
  doingAt: string | null;
  closedAt: string | null;
  updatedAt: string;
}

export interface TicketNoteView {
  id: string;
  organizationReference: string;
  ticketId: string;
  body: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export enum TicketAssignationStrategy {
  NONE = "none",
  ROLE = "role",
}

export enum TicketAssignationAlgorithm {
  RANDOM = "random",
}

export interface TicketAssignationRoleConfig {
  rolesToAssign: string[];
  algorithm?: TicketAssignationAlgorithm;
}

export interface TicketTypeConfigView {
  writeNoteBeforeStatusTransition: boolean | TicketStatus[];
  requiredClosedReason: boolean;
  assignationStrategy: TicketAssignationStrategy;
  assignationRoleConfig?: TicketAssignationRoleConfig;
}

export interface TicketType {
  organizationReference: string;
  name: string;
  code: string;
  color: string;
  description: string;
  closedReasons: string[];
  viewRoles: string[];
  editRoles: string[];
  config: TicketTypeConfigView;
  sequenceGeneratorName: string;
  archivedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export type LogType = "profile" | "chat" | "case";

export type ProfileAuditEvent = "profile.general.viewed" | "profile.audit.viewed";

export type ChatAuditEvent = "chat.answered" | "chat.read" | "chat.sent";

export type CaseAuditEvent =
  | "case.statusChanged.inProgress"
  | "case.detected"
  | "case.viewed"
  | "case.statusChanged.closed";

export type AuditEvent = ProfileAuditEvent | ChatAuditEvent | CaseAuditEvent;

export interface AuditLog {
  responsibleId: string;
  responsibleName: string;
  employeeId: string;
  employeeName: string;
  event: AuditEvent;
  reference: string;
  type: LogType;
  date: Date;
  ip?: string;
}

export enum ChatStatus {
  CREATING = "creating",
  ERROR = "error",
  STARTED = "started",
  EXPIRED = "expired",
  ANSWERED = "answered",
  PARTIALLY_ANSWERED = "partially_answered",
}
export enum QuestionStatus {
  SKIPPED = "skipped",
  ASKED = "asked",
  NOT_EVALUATED = "not_evaluated",
}

export enum QuestionType {
  MOOD = "mood",
  REASON = "reason",
  NPS = "nps",
  TEXT = "text",

  // Without reason
  TWO_OPTIONS = "two_options",
  THREE_OPTIONS = "three_options",
  FOUR_OPTIONS = "four_options",
  FIVE_OPTIONS = "five_options",
  SEVEN_OPTIONS = "seven_options",
}

export enum QuestionRuleActionType {
  JUMP = "jump",
  JUMP_TO_END = "jump_to_end",
}

export interface ChatBasics {
  id: string;
  employeeId: string;
  mood?: Mood;
  startedAt: Date;
  finishedAt?: Date;
  engagementScore: number;
  status: ChatStatus;
}

export interface Chat extends ChatBasics {
  questionAndAnswers: QuestionAndAnswer[];
}

interface BaseQuestionRuleAction {
  type: QuestionRuleActionType;
}
export interface JumpQuestionRuleAction extends BaseQuestionRuleAction {
  type: QuestionRuleActionType.JUMP;
  value: string;
}
export interface JumpToEndQuestionRuleAction extends BaseQuestionRuleAction {
  type: QuestionRuleActionType.JUMP_TO_END;
}

export type QuestionRuleAction = JumpQuestionRuleAction | JumpToEndQuestionRuleAction;

export interface QuestionRuleCondition {
  questionId: string;
  answer: string[];
}

export interface QuestionRule {
  action: QuestionRuleAction;
  conditions: QuestionRuleCondition[];
}

type ChatWorkflowQuestionOutput = {
  questionId: string;
  rules: QuestionRule[];
  order: number;
  status: QuestionStatus;
  evaluatedAt: Date | null;
};

export interface QuestionAndAnswer {
  question: string;
  answer?: string;
  workflow: ChatWorkflowQuestionOutput;
  subDriver?: string;
  favorability?: Favorability;
  type: QuestionType;
  module: Module;
  answerOption?: number;
  weight: number;
}

export interface SerializedChat {
  id: string;
  createdAt: string;
  finishedAt?: string;
}

export interface Note {
  id: string;
  riskCaseReference: string;
  creatorReference: string;
  creatorName: string;
  creatorAvatar: string;
  body: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface RiskCase extends RiskCaseBasics {
  auditLogs: AuditLog[];
  chats: SerializedChat[];
  notes: Note[];
}

export enum RiskCaseClosedReason {
  ACTION_PLAN_CREATED = "action_plan_created",
  PROBLEM_RESOLVED = "problem_resolved",
  IGNORE = "ignore",
  NOT_A_CASE = "not_a_case",
  RESIGNED = "resigned",
  DUPLICATED_CASE = "duplicated_case",
  ADD_NEW_ONE = "add_new_one",
}

export enum Module {
  DEV_ENG = "dev_eng",
  ONBOARDING = "onboarding",
  OFFBOARDING = "offboarding",
  NPS = "nps",
  ONE_TIME_QUESTION = "one_time",
  TEST = "test",
  FEEDBACK = "feedback",
}

/**
 * This is the list of all modules that are available in the platform.
 * Some of them are not available for filtering. For example, the NPS module is not available for filtering.
 * @question Should they?
 */
export const ALL_MODULE_VALUES: Module[] = [
  Module.ONBOARDING,
  Module.DEV_ENG,
  Module.OFFBOARDING,
  Module.ONE_TIME_QUESTION,
];

export enum ReportsChatStatus {
  CREATING = "creating",
  EXPIRED = "expired",
  ANSWERED = "answered",
}
export type ALL_REPORTS_CHAT_STATUS = "creating" | "expired" | "answered";

export const ALL_REPORTS_CHAT_STATUS_VALUES: ReportsChatStatus[] = [
  ReportsChatStatus.CREATING,
  ReportsChatStatus.EXPIRED,
  ReportsChatStatus.ANSWERED,
];

export enum DriverPerformance {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  NO_DATA = "no_data",
}

export enum ExtendedDriverPerformance {
  AMAZING = "amazing",
  GREAT = "great",
  GOOD = "good",
  LOW = "low",
  VERY_LOW = "veryLow",
  CRITICAL = "critical",
  NO_DATA = "no_data",
}

export interface DriverAndSubDriver {
  driver: string;
  subDriver?: string;
}

export enum Severity {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum RiskCaseStatus {
  DETECTED = "detected",
  IN_PROGRESS = "inProgress",
  CLOSED = "closed",
}

export enum Mood {
  CRY = 1,
  SAD = 2,
  NEUTRAL = 3,
  HAPPY = 4,
  LOVE = 5,
}

export const EMOJI_BY_MOOD: Record<Mood, string> = {
  1: "😢",
  2: "🙁",
  3: "🙂",
  4: "😄",
  5: "😍",
};

export enum Favorability {
  POSITIVE = "positive",
  NEUTRAL = "neutral",
  NEGATIVE = "negative",
}

export const FAVORABILITY_OPTIONS = Object.values(Favorability);

export const FAVORABILITY_BY_MOOD: Record<Mood, Favorability> = {
  1: Favorability.NEGATIVE,
  2: Favorability.NEGATIVE,
  3: Favorability.NEUTRAL,
  4: Favorability.POSITIVE,
  5: Favorability.POSITIVE,
};

export interface AggregationBucket<Value = number, Label = string, Key = string> {
  key: Key;
  count: number | null;
  outOf: number | null;
  values: { value: Value; label: Label; total: Value }[];
}

export type MoodProgressionBucket = AggregationBucket<number, string, Mood>;

export interface MoodByTenureData {
  groupBy: "mood";
  buckets: MoodProgressionBucket[];
}

export interface AggregationMedianBucket<Value = number, Label = string> {
  key: string;
  median: number;
  count: number;
  values: { value: Value; label: Label }[];
}

export type MoodBucket = AggregationMedianBucket<number, Mood>;

export interface MoodHeatmapData {
  groupBy: string;
  buckets: MoodBucket[];
}

export interface MoodTimelineValue {
  organization_reference: string;
  date: string;
  total_response: number;
  quantity_1: number;
  quantity_2: number;
  quantity_3: number;
  quantity_4: number;
  quantity_5: number;
}

export type MoodTimelineData = MoodTimelineValue[];

export type LastMonthDriversData = Record<string, number>;

export interface FeedbackDashboard {
  lastMonthDrivers: LastMonthDriversData;
}

export interface RiskCasesHighlightsType {
  mostRecent: RiskCaseBasics[];
}

export interface HomeDashboard {
  riskCasesHighlights: RiskCasesHighlightsType;
}

export interface ResponseRateHomeDashboard {
  activeEmployees: number;
  chatsAnswered: number;
  contactedEmployees: number;
  chatsResponseRate: number;
  chatsCreating: number;
}

export interface PersonalizedHomeDashboard {
  cases: RiskCaseBasics[];
}

export interface CasesTimelineData {
  series: {
    name: string;
    data: number[];
  }[];
  xaxis: string[];
}

export interface DataPoint {
  date: string;
  value: number | null;
}

export interface EngagementHomeHistoryResponse {
  organizationHistory: DataPoint[];
  driversHistory: Record<string, DataPoint[]>;
  onboardingSubDriversHistory: Record<string, DataPoint[]>;
}

export interface DriverAndSubdriverHistory {
  devEngDriversHistory: Record<string, DataPoint[]>;
  onboardingSubDriversHistory: Record<string, DataPoint[]>;
}

export enum TeamType {
  DIRECT_REPORTS = "direct_reports",
  INDIRECT_REPORTS = "indirect_reports",
  CUSTOM = "custom",
}

export interface DepartmentSegmentFilter {
  department: string;
}

export interface TeamSegmentFilter {
  team: string;
}

export interface EmployeeIdsSegmentFilter {
  employee_ids: string[];
}

export type CustomSegmentFilter = EmployeeIdsSegmentFilter | DepartmentSegmentFilter | TeamSegmentFilter;

export interface TeamManager {
  id: string;
  fullName: string;
  jobTitle?: string;
  principalManagerId: string | null;
}

export interface Team {
  id: string;
  name: string;
  avatar?: string;
  managers: TeamManager[];
  type: TeamType;
  childrenTeams: Team[];
  countDirectReports?: number;
  countIndirectReports?: number;
  createdAt: string;
  filters?: CustomSegmentFilter;
  membersCount?: number;
}

export interface TeamWithMembers extends Team {
  members: { id: string; firstName: string; lastName: string; avatar?: string; jobTitle: string }[];
}

export type DriverMetrics = Record<string, { value: number; subDrivers?: DriverMetrics }>;

export interface TeamMetrics {
  minimumReached: boolean;
  totalEmployees: number;
  seeingAs: OldRoleCode;
  overwritesMinimumResponseSize: boolean;
  historyAvailable: boolean;
  totalEmployeesAfterOnboarding: number;
  minimumEmployees: number;
  engagement: { totalResponses: number; engagementScore: number };
  driversMetrics: DriverMetrics;
  moodMetrics: DataPoint[];
  responseStatus?: {
    onboarding: {
      total: number;
      collaborators: Pick<User, "firstName" | "lastName" | "avatar">[];
    };
    dev_eng: {
      total: number;
      totalResponse: number;
      totalNotResponse: number;
      totalNotContacted: number;
      collaborators: Pick<User, "firstName" | "lastName" | "avatar">[];
    };
  };
  engagementScoreHistory: DataPoint[];
  comparisonEngagementScoreHistory: DataPoint[];
}

export interface CasesHomeHistory {
  casesHistory: Record<RiskCaseStatus, DataPoint[]>;
  actualCases: {
    total_detected: number;
    total_inprogress: number;
  };
}

export interface StaticHomeDashboard {
  casesTimeline: CasesTimelineData;
}

export interface WithParticipation<T> {
  totalParticipation: number;
  data: T;
}

export interface FavorabilityMetrics {
  totalResponses: number;
  totalPositive: number;
  totalNeutral: number;
  totalNegative: number;
}

/**
 * @deprecated Use {@link QuestionSummary} with the questionsSummaryReport.
 */
export interface OldQuestionSummary {
  id: string;
  translation: string;
  createdAt: string;
  subDriver: string;
  driver: string;
  totalResponses: number;
  totalPositive: number;
  totalNeutral: number;
  totalNegative: number;
}

export interface QuestionSummary {
  questionId: string;
  questionType: QuestionType;
  questionText: string;
  lastResponseAt: string;
  module: Module;
  subDriver: string;
  driver: string;
  totalResponses: number;
  totalPositive: number;
  totalNeutral: number;
  totalNegative: number;
}

export interface QuestionDetailAnswer {
  chatId: string;
  questionId: string;
  employeeReference: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  leavingDate: string;
  avatar: string;
  favorability: Favorability | null;
  lastAnsweredAt: string;
}

export interface AnswerByQuestion {
  questionId: string;
  questionType: QuestionType;
  numericAnswer: number;
  totalResponses: number;
}

/**
 * @deprecated Use {@link QuestionDetailAnswer} with the report instead.
 */
export interface OldQuestionDetailAnswer {
  employeeReference: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  leavingDate: string;
  avatar: string;
  favorability: Favorability;
  createdAt: string;
  chatId: string;
}

export interface SlackApp {
  id: string;
  clientId: string;
  appId: string;
  name: string;
  redirectUri: string;
}

export interface QuestionDefinitionOption {
  text: string;
  value: string;
}

export interface QuestionDefinition {
  id: string;
  label: string;
  type: QuestionType;
  options: QuestionDefinitionOption[];
  subDriver?: string;
  translationCode: string;
  organizationReference: string;
  locale: string;
  module: Module;
}

export type DriverEngagementScore = {
  driver: string;
  engagementScore: number;
  subDrivers?: DriverEngagementScore[];
};

export interface OrganizationMetrics {
  engagementScore: number;
  driverEngagementScore: DriverEngagementScore[];
}

export enum EnpsFavorability {
  PROMOTER = "promoter",
  DETRACTOR = "detractor",
  PASSIVE = "passive",
}

export interface EnpsItem {
  answeredCount: number;
  contactedCount: number;
  date: string;
  detractorCount: number | null;
  enpsScore: number | null;
  neverContactedCount: number;
  passiveCount: number | null;
  pendingCount: number | null;
  promoterCount: number | null;
  responseRate: string;
}

export interface TextAnswer extends Partial<DriverAndSubDriver> {
  employee: EmployeeBasics;
  text: string;
  createdAt: string;
  chatReference: string;
  question?: string;
  questionId?: string;
}

export enum LaraScoreAssessment {
  AMAZING = "amazing",
  GREAT = "great",
  GOOD = "good",
  LOW = "low",
  VERY_LOW = "veryLow",
  CRITICAL = "critical",
}

export enum IntegrationType {
  COMMUNICATION = "communications",
  HRIS = "hris",
}

export enum IntegrationHrisType {
  GOOGLE_SHEET = "gsheet",
  BAMBOO = "bamboo",
  HI_BOB = "hi_bob",
  WORKDAY = "workday",
  SUCCESS_FACTOR = "success_factor",
  FILE = "file",
  SFTP = "sftp",
  S3 = "s3",
  FACTORIAL = "factorial",
  KALLIDUS = "kallidus",
  VISMA = "visma",
  RANKMI = "rankmi",
  VOM = "vom",
  SHAREPOINT_FILE = "sharepoint_file",
  SHAREPOINT_FOLDER = "sharepoint_folder",
  API = "api",
}

export function isHrisIntegration(integration: unknown): integration is HrisIntegration {
  return Object.values(IntegrationHrisType).includes(integration as IntegrationHrisType);
}

export enum IntegrationCommunicationType {
  GOOGLE_CHAT = "gchat",
  TEAMS = "teams",
  SLACK = "slack",
  WHATSAPP = "whatsapp",
  WORKPLACE = "workplace",
}

export const CommunicationChannelsOptions = {
  PRINCIPAL: "principal",
  GOOGLE_CHAT: "gchat",
  TEAMS: "teams",
  SLACK: "slack",
  WHATSAPP: "whatsapp",
  WORKPLACE: "workplace",
} as const;

export type CommunicationChannels = (typeof CommunicationChannelsOptions)[keyof typeof CommunicationChannelsOptions];

export interface IntegrationResult {
  id: string;
  executedAt: string;
  executedBy: string;
  result: {
    totals: {
      added: number;
      error: number;
      removed: number;
      updated: number;
    };
    details: {
      added: number;
      error: number;
      removed: number;
      updated: number;
    };
  };
}

export interface HrisIntegration {
  reference: string;
  integration: IntegrationHrisType;
  lastSync?: IntegrationSyncResponse;
}

export interface CommunicationIntegration {
  reference: string;
  channel: IntegrationCommunicationType;
  lastSync?: IntegrationSyncResponse;
}

export type UnknownIntegration = Partial<CommunicationIntegration> & Partial<HrisIntegration> & { reference: string };
export type AllIntegrationTypes = IntegrationHrisType | IntegrationCommunicationType;

export interface Integrations {
  [IntegrationType.HRIS]: HrisIntegration[];
  [IntegrationType.COMMUNICATION]: CommunicationIntegration[];
}

export interface Organization {
  id: string;
  code: string;
  name: string;
  integrations?: Integrations;
  offboardingCohorts: OffboardingCohorts;
  loginPolicy: LoginPolicyConfiguration;
  schedulingConfig: OrganizationSchedulingConfig;
}

export interface SlackIntegration {
  id: string;
  app: SlackApp;
}

export interface GSheetIntegration {
  id: string;
  sheetId: string;
  integratedBy: string;
  organizationReference: string;
  mappings: Record<string, { clientLabel: string; index: number }>;
  createdAt: string;
  updatedAt: string;
}

export type GSheetFileSource = {
  name: "gsheet";
  spreadsheetId: string;
};

export type BambooSource = {
  name: "bamboo";
  /**
   * Typed as never because it's not returned by the API.
   */
  apiKey: never;
  companyDomain: string;
};

export type FileSource =
  | S3FileSource
  | GSheetFileSource
  | BambooSource
  | SftpFileSource
  | SharepointFileSource
  | SharepointFolderSource;

type Relationship =
  | {
      type: "rowIdentifier";
    }
  | {
      type: "property";
      property: string;
    };

export interface FileIntegration {
  id: string;
  integratedBy: string;
  organizationReference: string;
  mappings: Record<string, string>;
  configurations: {
    rowIdentifier: string;
    relationships: {
      hrbps: Relationship;
      manager: Relationship;
    };
    frontPlate: {
      name:
        | "inboundSftp"
        | "sftp"
        | "gsheet"
        | "visma"
        | "rankmi"
        | "vom"
        | "factorial"
        | "kallidus"
        | "bamboo"
        | "sharepointFile"
        | "sharepointFolder"
        | "api";
      sourceName?: string;
    };
    source: FileSource;
    defaultValues: Record<string, unknown>;
    employeePrint: {
      fields: IntegrationMappableField[][];
    };
  };
  createdAt: string;
  updatedAt: string;
}

export enum IntegrationMappableField {
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  NICKNAME = "nickname",
  GENDER = "gender",
  BIRTHDATE = "birthdate",
  AVATAR = "avatar",
  PHONE = "phone",
  JOB_TITLE = "jobTitle",
  LOCALE = "locale",
  CONTACT_ENABLED = "contactEnabled",
  CONTACT_DISABLED = "contactDisabled",
  COHORT_REFERENCE = "cohortReference",
  STARTED_WORKING_DATE = "startedWorkingDate",
  HRMS_REFERENCE = "hrmsReference",
  PRINCIPAL_MANAGER_ID = "principalManagerId",
  HRBP_IDS = "hrbpIds",
  WORK_MODALITY = "workModality",
  TEAMS = "teamNames",
  DEPARTMENT = "department",
  SUB_DEPARTMENT = "subDepartment",
  DIVISION = "division",
  LOCATION = "location",
  CITY = "city",
  COUNTRY = "country",
  LEAVING_DATE = "leavingDate",
  LEAVING_REASON_TYPE = "leavingReasonType",
  LEAVING_REASON_DETAIL = "leavingReasonDetail",
  GRADE = "employeeGrade",
  PERFORMANCE_SCORE = "performanceScore",
  FAQS_ENABLED = "faqsEnabled",
  COMMUNICATIONS_PRIORITIES = "communicationsPriorities",
  COMPANY_NAME = "companyName",
}

export enum AvatarSize {
  LARGE = "large",
  NORMAL = "normal",
  MEDIUM = "medium",
  SMALL = "small",
}

export enum DeliveryApproval {
  PENDING = "pending",
  APPROVED = "approved",
  NOT_APPROVED = "not_approved",
}

export interface ScheduledContact {
  id: string;
  organizationReference: string;
  employeeReference: string;
  scheduledEmployee: string;
  locale: string;
  deliveryApprovalStatus: DeliveryApproval;
  channel: IntegrationCommunicationType;
  sendAfter: string;
  sendBefore: string;
  sentAt: string | null;
  chatReference: string | null;
  touchpointId: string;
  cohortId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ScheduledContactWithEmployee extends ScheduledContact {
  employee?: EmployeeBasics;
}

export enum FeedbackType {
  FROM_CHAT = "from_chat",
  PROACTIVE = "proactive",
  ENPS = "enps",
}

export enum CommunicationsPermissions {
  COMMUNICATIONS_SENDING_LIST = "communications.sending:list",
  COMMUNICATIONS_SENDING_VIEW = "communications.sending:view",
  CREATE_ONETIME = "communications.onetime:create",
  TEMPLATE_LIST = "communications.templates:list",
  TEMPLATE_CREATE = "communications.templates:create",
  TEMPLATE_MODIFY = "communications.templates:modify",
  TEMPLATE_DELETE = "communications.templates:delete",
  TEMPLATE_PREVIEW_EMPLOYEE = "communications.templates.preview.employee:list",
  TEMPLATE_EDITOR_FULL = "communications.templates.editor.full:view",
}

export const FeedbackTypeByPermission: Record<FeedbackType, string> = {
  [FeedbackType.FROM_CHAT]: "feedback.fromChats:list",
  [FeedbackType.PROACTIVE]: "feedback.proactive:list",
  [FeedbackType.ENPS]: "feedback.nps:list",
};

export enum ChatPermissions {
  CHAT_CHANNEL_DISABLE = "chat.channel.user:disable",
  CHAT_CHANNEL_ENABLE = "chat.channel.user:enable",
}

export const FeedbackTypeListPermissions = Object.values(FeedbackTypeByPermission);

export type FeedbackBase = {
  id: string;
  readAt: string | null;
  organizationReference: string;
  message: string;
  employeeReference: string;
  answerReference?: string;
  createdAt: string;
  tags: string[];
  readBy: string | null;
  employee?: EmployeeBasics;
  isAnonymous: boolean;
  wasAutoTagged: boolean;
};

export interface FeedbackFromChat extends FeedbackBase {
  type: FeedbackType.FROM_CHAT;
  chatReference: string;
  question: string;
  questionId: string;
  subDriver: string;
}

export interface FeedbackProactive extends FeedbackBase {
  type: FeedbackType.PROACTIVE;
}

export interface FeedbackEnps extends FeedbackBase {
  type: FeedbackType.ENPS;
  numericAnswer: number;
  favorability: Favorability;
  isAnonymous: true;
}

export type Feedback = FeedbackFromChat | FeedbackProactive | FeedbackEnps;

export enum FeedbackSummaryStatus {
  PENDING = "pending",
  GENERATING = "generating",
  SUCCESS = "success",
  ERROR = "error",
}

export interface FeedbackSummaryView {
  id: string;
  organizationReference: string;
  name: string;
  from: string;
  to: string;
  feedbackCount: number | null;
  locale: string;
  status: FeedbackSummaryStatus;
  filters: Record<string, string>;
  createdBy: string;
  summary: string | null | undefined;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export type FeedbackSummaryListingView = Omit<FeedbackSummaryView, "summary">;

export const isFeedbackFromChat = (feedback: Feedback): feedback is FeedbackFromChat =>
  feedback.type === FeedbackType.FROM_CHAT;

export const isFeedbackProactive = (feedback: Feedback): feedback is FeedbackProactive =>
  feedback.type === FeedbackType.PROACTIVE;

export const isFeedbackEnps = (feedback: Feedback): feedback is FeedbackEnps => feedback.type === FeedbackType.ENPS;

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<Required<T[P]>>;
};

export type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;

export interface Cohort {
  id: string;
  name: string;
  organizationReference: string;
}

export enum LeavingReasonType {
  QUIT = "quit",
  FIRED = "fired",
  NO_SHOW = "no_show",
}

export enum SegmentOwnerType {
  ORG = "org",
  HRBP = "hrbp",
  TEAM = "team", // deprecated
  MANAGER = "manager",
  ROLE = "role",
  USER = "user",
}

/**
 * Type a Record with a enum but don't require all the keys to be present.
 */
export type Some<Enum extends string | symbol, V> = { [K in Enum]?: V };

export enum MfaProvider {
  TOTP = "totp",
}
export interface OffboardingCohorts {
  // default cohort when none of the other status match
  default?: string;
  // cohort when someone is fired
  fired?: string;
  // cohort when someone quits
  quit?: string;
  // cohort when someone does not show up
  no_show?: string;
}

export interface PasswordRules {
  minLength: number;
  minLowerAlpha: number;
  minUpperAlpha: number;
  minSpecial: number;
  minNumbers: number;
}

export const defaultPasswordRules = {
  minLength: 12,
  minLowerAlpha: 1,
  minUpperAlpha: 1,
  minSpecial: 1,
  minNumbers: 1,
};

export interface LoginPolicyConfiguration {
  mfaProviders: MfaProvider[];
  enforceMfa: boolean;
  passwordRules: PasswordRules;
}
export interface OrganizationSchedulingConfig {
  timeZone: "etc/utc"; // for now, for simplicity it will always be utc.
  hours: number; // hours, between 0 and 23.
}

export const ALL_LOCALES = ["en", "es", "es-AR", "pt"] as const;

export type TranslationLocales = (typeof ALL_LOCALES)[number];

export const TranslationLocalesValues: Record<TranslationLocales, string> = {
  en: "English",
  es: "Español",
  "es-AR": "Español (Argentina)",
  pt: "Português",
};

export interface Translation {
  id: string;
  organizationReference: string;
  locale: string;
  value: string;
  key: string;
  version: string | null;
  updatedAt: string;
}
