import { ManagerTeamCustom, ManagerTeamHierarchy, isManagerTeamCustom } from "@/hooks/useTeams";
import { MenuItem } from "@mui/material";
import { FC } from "react";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";
import { AvatarSize } from "@/types/common";

export const TeamItem: FC<{
  team: ManagerTeamCustom | ManagerTeamHierarchy;
  onClick?: (item: ManagerTeamCustom | ManagerTeamHierarchy) => void;
  selected?: boolean;
}> = ({ team, onClick, selected }) => {
  const title = team.teamName;
  const subtitle = isManagerTeamCustom(team) ? team.managerName : "";

  return (
    <MenuItem onClick={() => onClick?.(team)} selected={selected}>
      <ProfileAvatar
        size={AvatarSize.SMALL}
        user={{
          name: title,
          jobTitle: subtitle,
        }}
      />
    </MenuItem>
  );
};
