import { AvailableLanguages } from "@/translations/AvailableLanguages";
import { mapValues } from "lodash";
import { Command } from "cmdk";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { CommandMenuNoResultsFound } from "./CommandMenuNoResultsFound";

const CommandMenuChangeLanguagePage = () => {
  const { t, i18n } = useTranslation();
  const languageOptions = mapValues(groupBy(AvailableLanguages, "language"), (v) => v[0]);
  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Command.Group heading={t("common.labels.changeLanguage")} className="cmdk-group">
        {Object.values(languageOptions).map((language) => (
          <Command.Item
            key={language.language}
            onSelect={() => onChangeLanguage(language.language)}
            className="cmdk-item"
          >
            <Typography variant="body1">{language.label}</Typography>
            {language.language === i18n.language ? (
              <Typography variant="caption" color="text.secondary">
                {t("common.labels.current")}
              </Typography>
            ) : null}
          </Command.Item>
        ))}
      </Command.Group>
      <CommandMenuNoResultsFound />
    </>
  );
};

export { CommandMenuChangeLanguagePage };
