import { Box, Typography } from "@mui/material";
import { Command } from "cmdk";
import { useTranslation } from "react-i18next";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { NavSectionItem } from "@/modules/skeleton/components/sidebar/NavSection";
import { useNavigate } from "react-router";
import { getSidebarSections } from "@/routing/SidebarSections";
import useAuth from "@/hooks/useAuth";
import { PermissionCode } from "@/types/PermissionCode";
import { usePermission, useSomePermission } from "@/hooks/usePermissions";

type Props = {
  onNavigate: () => void;
};

const CommandMenuNavigation = ({ onNavigate }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { jwtUser } = useAuth();
  const routesConfig = getSidebarSections(jwtUser)?.[0]?.items ?? [];
  const flattenedRoutesConfig = routesConfig.flatMap((item) => item.children ?? [item]);

  const { granted: canEditUser } = usePermission(PermissionCode.USER_EDIT);
  const { granted: canDeleteUser } = usePermission(PermissionCode.USER_DELETE);
  const { granted: canCreateUser } = usePermission(PermissionCode.USER_CREATE);
  const { granted: canResetPassword } = usePermission(PermissionCode.USER_RESET_PASSWORD);

  const canUserManagement = canEditUser || canCreateUser || canDeleteUser || canResetPassword;

  const { granted: canListIntegrations } = useSomePermission([
    PermissionCode.INTEGRATION_CHANNEL_LIST,
    PermissionCode.INTEGRATION_HRMS_LIST,
  ]);

  if (canUserManagement) {
    flattenedRoutesConfig.push({
      label: (t) => t("settings.userManagement.tab"),
      name: "users",
      path: "/settings/users",
    });
  }

  if (canListIntegrations) {
    flattenedRoutesConfig.push({
      label: (t) => t("settings.tabs.integrations"),
      name: "integrations",
      path: "/settings/integrations",
    });
  }

  const handleSelect = (item: NavSectionItem) => {
    if (item.path) {
      navigate(item.path);
      onNavigate();
    }
  };

  return (
    <>
      <Command.Group
        heading={
          <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
            <Typography variant="overline" color="text.secondary">
              {t("common.labels.navigation")}
            </Typography>
            <AccountTreeIcon sx={{ color: "text.secondary" }} />
          </Box>
        }
        className="cmdk-group"
        style={{ paddingBottom: 12 }}
      >
        {flattenedRoutesConfig.map((item) => (
          <Command.Item key={item.name} onSelect={() => handleSelect(item)} className="cmdk-item">
            {item.label(t)}
          </Command.Item>
        ))}
      </Command.Group>
    </>
  );
};

export { CommandMenuNavigation };
