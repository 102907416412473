import useAuth from "@/hooks/useAuth";
import { useCurrentLanguage } from "@/hooks/useCurrentLanguage";
import { DriversWithSubDrivers, fetchDrivers } from "@/services/driverService";
import { useQuery } from "@tanstack/react-query";
import { FC, createContext } from "react";

interface DriversContextValue {
  drivers: DriversWithSubDrivers[];
  loading: boolean;
  error: unknown | null;
}

export const DriversContext = createContext<DriversContextValue>({
  drivers: [],
  loading: true,
  error: null,
});

export const DriversProvider: FC = ({ children }) => {
  const auth = useAuth();
  const locale = useCurrentLanguage();

  const {
    data = [],
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["drivers", auth.organizationReference, locale],
    queryFn: () => fetchDrivers(auth.organizationReference, locale),
    enabled: auth.isAuthenticated && !!auth.organizationReference,
  });

  return <DriversContext.Provider value={{ drivers: data, loading, error }}>{children}</DriversContext.Provider>;
};
