import { searchEmployee, SearchEmployeesParams } from "@/services/employeeService";
import { useInfiniteQuery } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import { Command } from "cmdk";
import { LinearProgress, Typography } from "@mui/material";
import { IntersectionObserverElement } from "../IntersectionObserverElement";
import { useNavigate } from "react-router-dom";
import { CommandMenuNoResultsFound } from "./CommandMenuNoResultsFound";

const PAGE_SIZE = 10;
type CommandMenuSearchEmployeesPageProps = {
  search: string;
  onSelect: () => void;
};

const CommandMenuSearchEmployeesPage = ({ search, onSelect }: CommandMenuSearchEmployeesPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSelectEmployee = (employeeId: string) => {
    navigate(`/employees/${employeeId}`);
    onSelect();
  };

  const employeesQuery = useInfiniteQuery({
    queryKey: ["employee.search", search],
    queryFn: async ({ pageParam }) => {
      const params: SearchEmployeesParams = {
        manager: false,
        showFormer: false,
        page: pageParam,
        pageSize: PAGE_SIZE,
      };

      if (search) {
        params.searchTerm = search;
      }

      const employees = await searchEmployee(params, String(pageParam));

      const maxPages = Math.ceil(employees.total / PAGE_SIZE);

      return {
        data: employees.elements.map((e) => ({ value: e.id, label: e.name, avatar: e.avatar, email: e.email })),
        next: employees.page + 1 < maxPages ? employees.page + 1 : undefined,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
  return (
    <>
      <Command.Group heading={t("common.labels.searchEmployees")} className="cmdk-group">
        {employeesQuery.isFetching ? <LinearProgress /> : null}
        {employeesQuery?.data?.pages.flatMap((page) =>
          page.data.map((option) => (
            <Command.Item key={option.value} onSelect={() => onSelectEmployee(option.value)} className="cmdk-item">
              <Typography variant="body1">
                {option.label}{" "}
                <Typography variant="caption" color="text.secondary">
                  ({option.email})
                </Typography>
              </Typography>
            </Command.Item>
          ))
        )}
        {employeesQuery.isFetchingNextPage ? <LinearProgress /> : null}
        {!employeesQuery.isPending &&
          !employeesQuery.isError &&
          !employeesQuery.isFetchingNextPage &&
          employeesQuery.hasNextPage && (
            <IntersectionObserverElement onScrollIntersect={() => employeesQuery.fetchNextPage()} />
          )}
      </Command.Group>
      {!employeesQuery.isPending && <CommandMenuNoResultsFound />}
    </>
  );
};

export { CommandMenuSearchEmployeesPage };
