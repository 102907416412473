import { TeamTreeNavigation } from "@/hooks/useTeams";
import { Box, MenuItem } from "@mui/material";
import { FC, Fragment } from "react";
import { ProfileAvatar } from "../profile-avatar/ProfileAvatar";
import { AvatarSize } from "@/types/common";
import { IconPath, IconPathType } from "./IconPath";

export const RecursiveTreeNavigation: FC<{
  tree: TeamTreeNavigation[];
  onClick: (item: TeamTreeNavigation) => void;
  isSearching: boolean;
  selected?: (item: TeamTreeNavigation) => boolean;
}> = ({ tree, onClick, isSearching, selected }) => {
  return (
    <>
      {tree.map((item, index) => (
        <Fragment key={`${item.teamName}-${index}`}>
          <MenuItem onClick={() => onClick(item)} selected={selected?.(item)}>
            <Box sx={{ position: "relative", zIndex: 2 }}>
              <ProfileAvatar
                size={AvatarSize.SMALL}
                user={{
                  name: item.teamName,
                }}
              />
            </Box>

            {!isSearching && !(index === tree.length - 1 && item.children.length === 0) && (
              <IconPath type={item.children.length > 0 ? IconPathType.LEFT_BOTTOM : IconPathType.BOTTOM} />
            )}
          </MenuItem>

          {item.children && (
            <Box sx={{ position: "relative", left: "20px", top: "0px" }}>
              <RecursiveTreeNavigation
                tree={item.children}
                onClick={onClick}
                isSearching={isSearching}
                selected={selected}
              />
            </Box>
          )}
        </Fragment>
      ))}
    </>
  );
};
